import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MenuItem from './menuItem'
import MenuTree from './menuTree'
import { withRouter } from 'react-router-dom';

import { carregarUsuario } from '../../auth/authActions';

class Menu extends Component {

	constructor(props) {
		super(props);

		let idInterval = setInterval(() => {
			if (this.props.usuario) {
				this.props.carregarUsuario();
				clearInterval(idInterval);
			}
		}, 2000);
	}

	render() {

		let selecionado = '';
		if (this.props.location && this.props.location.pathname) {
			selecionado = this.props.location.pathname;
		}

		return (
			<nav className="mt-2">
				<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
					<MenuItem path='/' label='Estação' icon='fas fa-sun' active={selecionado == '/'} />
					<MenuItem path='/previsao' label='Previsão do Tempo' icon='fas fa-calendar-alt' active={selecionado == '/previsao'} />
					<MenuItem path='/historicoDia' label='Histórico Diário' icon='fas fa-calendar-day' active={selecionado == '/historicoDia'} />
					<MenuItem path='/historicoMes' label='Histórico Mensal' icon='fas fa-calendar' active={selecionado == '/historicoMes'} />
				</ul>
			</nav>
		);
	}
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado
});
const mapDispatchToProps = dispatch => bindActionCreators({ carregarUsuario }, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));

