import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import '../common/template/custom.css';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';

import imagemUser from '../assets/images/user.jpg';

import {
	buscarPrevisao, buscarImagens
} from './previsaoActions';

class Previsao extends Component {

	state = {
		pesquisar: '',
		carrocelSelecionado: 0,
		botaoAtivoAjuricaba: false,
		botaoAtivoIjui: false
	}

	constructor(props) {
		super(props);
		
		this.state = {
			botaoAtivoAjuricaba: true,
			botaoAtivoIjui: false
		};

		setInterval(() => {
			this.proximoCarrocel();
		}, 10000);
	}
	
	anteriorCarrocel() {
		
		this.setState({
			...this.state,
			carrocelSelecionado: this.state.carrocelSelecionado - 1 == -1 ? 0 : this.state.carrocelSelecionado - 1 
		});
	}

	proximoCarrocel() {

		let { tempo, imagens } = this.props;

		let banners = (banners || []).filter(banner => (imagens.carrocel || []).includes(banner.id));

		this.setState({
			...this.state,
			carrocelSelecionado: this.state.carrocelSelecionado + 1 == banners.length ? 0 : this.state.carrocelSelecionado + 1
		});
	}

    componentDidMount() {

		this.props.buscarPrevisao(this.state.codigo_acesso);
		this.props.buscarImagens();

		setInterval(() => {
			this.props.buscarPrevisao(this.state.codigo_acesso);
			this.props.buscarImagens();
		}, 40000);
    }

	getDirecao(direcao){
		if (direcao >= 348.8 || direcao < 11.3){
			return { sigla:'N', nome:'Norte'}
		}
		if (direcao >= 11.3 && direcao < 33.8){
			return {sigla:'NNE', nome:'Norte-Nordeste'}
		}
		if (direcao >= 33.8 && direcao < 56.3){
			return {sigla:'NE', nome:'Nordeste'}
		}
		if (direcao >= 56.3 && direcao < 78.8){
			return {sigla:'ENE', nome: 'Nordeste-Leste'}
		}
		if (direcao >= 78.8 && direcao < 101.3){
			return {sigla:'L', nome: 'Leste'}
		}
		if (direcao >= 101.3 && direcao < 123.8){
			return {sigla:'ESE', nome:'Sudeste-Leste'}
		}
		if (direcao >= 123.8 && direcao < 146.3){
			return {sigla:'SE', nome:'Sudeste'}
		}
		if (direcao >= 146.3 && direcao < 168.8){
			return {sigla:'SSE', nome:'Sudeste-Sul'}
		}
		if (direcao >= 168.8 && direcao < 191.3){
			return {sigla:'S', nome:'Sul'}
		} 
		if (direcao >= 191.3 && direcao < 213.8){
			return {sigla:'SSO', nome:'Sul-Sudoeste'}
		}
		if (direcao >= 213.8 && direcao < 236.3){
			return {sigla:'SO', nome:'Sudoeste'}
		}
		if (direcao >= 236.3 && direcao < 258.8){
			return {sigla:'OSO', nome:'Sudeste-Oeste'}
		}
		if (direcao >= 258.8 && direcao < 281.3){
			return {sigla:'O', nome:'Oeste'}
		}
		if (direcao >= 281.3 && direcao < 303.8){
			return {sigla:'ONO', nome:'Oeste-Noroeste'}
		}
		if (direcao >= 303.8 && direcao < 328.3){
			return {sigla:'NO', nome:'Noroeste'}
		}
		if (direcao >= 328.3 && direcao < 348.8){
			return {nome:'NNO', nome:'Noroeste-Norte'} 
		}
	}
    render() {

		let { lista, imagens } = this.props;

		let banners = (imagens.banners || []).filter(banner => (imagens.carrocel || []).includes(banner.id));
		
		let previsaoAgora = this.props.lista[0];
		let diasSemana = [
			{ id: 0, nome: 'Domingo', nomeCompleto: 'Domingo', abreviacao: 'Dom' },
			{ id: 1, nome: 'Segunda', nomeCompleto: 'Segunda-feira', abreviacao: 'Seg' },
			{ id: 2, nome: 'Terça', nomeCompleto: 'Terça-feira', abreviacao: 'Ter' },
			{ id: 3, nome: 'Quarta', nomeCompleto: 'Quarta-feira', abreviacao: 'Qua' },
			{ id: 4, nome: 'Quinta', nomeCompleto: 'Quinta-feira', abreviacao: 'Qui' },
			{ id: 5, nome: 'Sexta', nomeCompleto: 'Sexta-feira', abreviacao: 'Sex' },
			{ id: 6, nome: 'Sábado', nomeCompleto: 'Sábado', abreviacao: 'Sáb' }
		];
	
		return (
			<Content>

				{console.log(JSON.stringify(this.props.sessao && this.props.sessao.codigo_acesso))&&
				<div></div>}
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<Grid cols='12 12 10 6'>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: imagens.tela1 && imagens.tela2 ? 'space-between' : 'center', verticalAligment: 'center', alignItems: 'center', marginBottom: 2 }}>
							{imagens.tela1 ? (
								<img
									style={{ width: 120, height: 90 }}
									src={imagens.tela1} />
							) : null}

							{imagens.tela2 ? (
								<img
									style={{ width: 120, height: 90 }}
									src={imagens.tela2} />
							) : null}
						</div>
					</Grid>
					<Grid cols='12 12 10 6'>
						<div class="botoes" style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '10px', backgroundColor: '#ffffff', marginBottom: '0.50rem'}} >
							<div style={{width: '50%'}}>
								<div class="botao-ajuricaba">
									<button style={{ width: '100%', textAlign: 'center', padding: '10px', color: '#ffffff', backgroundColor: this.state.botaoAtivoAjuricaba ? '#0019bb' : '#0045bb', borderRadius: '5px', display: 'inline-block'}} 
									className={this.state.botaoAtivoAjuricaba ? 'active' : ''}
									onClick={() => {
										this.props.buscarPrevisao(null);
										this.setState({ codigo_acesso: null, botaoAtivoIjui: false, botaoAtivoAjuricaba: true });
									}} 
									onMouseEnter={(e) => { if (!this.state.botaoAtivoAjuricaba) { e.target.style.backgroundColor = '#0019bb'}}} 
									onMouseLeave={(e) => { if (!this.state.botaoAtivoAjuricaba) { e.target.style.backgroundColor = '#0045bb'}}}>AJURICABA</button>
								</div>
							</div>
							<div style={{width: '50%'}}>
                       			<div className="botao-ijui">
                            		<button style={{ width: '100%', textAlign: 'center', padding: '10px', color: '#ffffff', backgroundColor: this.state.botaoAtivoIjui ? '#0019bb' : '#0045bb', borderRadius: '5px', display: 'inline-block' }}
									className={this.state.botaoAtivoIjui ? 'active' : ''}
									onClick={() => {
                                		this.props.buscarPrevisao("codigoAcessoIjui");
										this.setState({ codigo_acesso: "codigoAcessoIjui", botaoAtivoAjuricaba: false, botaoAtivoIjui: true  });
									}} 
									onMouseEnter={(e) => { if (!this.state.botaoAtivoIjui) { e.target.style.backgroundColor = '#0019bb'}}} 
									onMouseLeave={(e) => { if (!this.state.botaoAtivoIjui) { e.target.style.backgroundColor = '#0045bb'}}}>IJUÍ</button>
								</div>
							</div>
						</div>
					</Grid>
					{console.log(JSON.stringify(previsaoAgora,null,4))}
					<Grid cols='12 12 10 6'>
						<div class="info-box shadow-sm">
							<div class="info-box-content" style={{ alignItems: 'center', paddingTop: 10, paddingBottom: 10 }}>
								<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
									<div style={{ width: '50%', paddingRight: 10 }}>
										<div style={{ textAlign: 'right', color: '#white', fontSize: 14 }}>{diasSemana.filter(dia => dia.id == new Date().getDay())[0].nomeCompleto}, {DateFormat.getDataHoraAtual().split(' ')[1]}</div>
										<div style={{ textAlign: 'right', color: '#white', fontSize: 40, paddingTop: 30, fontWeight: 'bold' }}>{previsaoAgora ? parseInt(previsaoAgora.temperatura || 0) : '-'}°C</div>
									</div>
									<div style={{ width: '50%', paddingLeft: 10 }}>
										<div style={{ textAlign: 'left', color: '#' }}>Chuva: {previsaoAgora ? parseFloat(previsaoAgora.chuva || 0).toFixed(1).replace('.', ',') : '-'} mm</div>
										<div style={{ textAlign: 'left', color: '#white', paddingTop: 10 }}>Umidade: {previsaoAgora ? parseInt(previsaoAgora.umidade || 0) : '-'} %</div>
										<div style={{ textAlign: 'left', color: '#white', paddingTop: 10 }}>Vento: {previsaoAgora ? parseInt(previsaoAgora.velocidade_vento || 0) : '-'} km/h</div>
										<div style={{ textAlign: 'left', color: '#white', paddingTop: 10 }} title={previsaoAgora ? `Velocidade Média Do Vento: `+ parseFloat(previsaoAgora.media_velocidade_vento || 0).toFixed(1) + ' km/h '  : '-'}>Vento: {previsaoAgora ? parseInt(previsaoAgora.media_velocidade_vento || 0) : '-'} km/h</div>
										<div style={{ textAlign: 'left', color: '#white', paddingTop: 10 }} title={previsaoAgora ? this.getDirecao(parseFloat(previsaoAgora.direcao_vento || 0)).nome  : '-'}>Direção do vento: {previsaoAgora ? this.getDirecao(parseFloat(previsaoAgora.direcao_vento || 0)).sigla  : '-'} </div>
									</div>
								</div>
							</div>
						</div>
					</Grid>

					{lista.filter((item, i) => i > 0).map(item => (
						<Grid  key={item.datahora} cols='12 12 10 6'>
							<div class="info-box shadow-sm">
								<div class="info-box-content" style={{ alignItems: 'center' }}>
									<div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingTop: 10 }}>
										<div style={{ width: '50%' }}>
											<div style={{ textAlign: 'left', color: '#white', fontSize: 14, paddingLeft: '10px' }}>{diasSemana.filter(dia => dia.id == new Date(item.datahora).getDay())[0].nomeCompleto}</div>
										</div>
										<div style={{ width: '50%' }}>
											<div style={{ textAlign: 'right', color: '#white', fontSize: 14, fontWeight: 'bold', paddingRight: '10px' }}>{item ? parseInt(item.temperatura_minima || 0) : '-'}°C | {item ? parseInt(item.temperatura_maxima || 0) : '-'}°C</div>
										</div>	
									</div>
									<div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingTop: 10 }}>
										<div style={{ width: '50%' }}>
											<div style={{ textAlign: 'left', color: '#white', paddingLeft: '10px' }}>Chuva: {item ? parseFloat(item.chuva || 0).toFixed(1).replace('.', ',') : '-'} mm</div>
										</div>
										<div style={{ width: '50%' }}>
											<div style={{ textAlign: 'right', color: '#white', paddingRight: '10px' }}>Umidade: {item ? parseInt(item.media_umidade || 0) : '-'} %</div>
										</div>	
									</div>
									<div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingTop: 10, paddingBottom: 10 }}>
										<div style={{ width: '50%' }}>
											<div style={{ textAlign: 'left', color: '#white', paddingLeft: '10px' }}title={item ? `Velocidade Média Do Vento: `+ parseFloat(item.media_velocidade_vento || 0).toFixed(1) + ' km/h '  : '-'}>Vento: {item ? parseInt(item.media_velocidade_vento || 0) : '-'} km/h</div>
										</div>
										<div style={{ width: '50%' }}>
											<div style={{ textAlign: 'right', color: '#white', paddingRight: '10px' }} title={item ? this.getDirecao(parseFloat(item.media_direcao_vento || 0)).nome  : '-'}>Direção do vento: {item ? this.getDirecao(parseFloat(item.media_direcao_vento || 0)).sigla  : '-'} </div>
										</div>	
									</div>
								</div>
							</div>
						</Grid>
					))}
					
					<Grid cols='12 12 10 6'>
						<div id='carouselExampleControls' class='carousel slide' data-ride='carousel'>
							<div class='carousel-inner'>
								<ol class='carousel-indicators'>
									{banners.map((item, i) => (
										<li key={item.id} data-target='#carouselExampleIndicators' data-slide-to={i} class={i == this.state.carrocelSelecionado ? 'active' : null} ></li>
									))}
								</ol>
								{banners.map((item, i) => (
									<div key={item.id} class={`carousel-item ${i == this.state.carrocelSelecionado ? 'active' : ''}`}>
										<img class='d-block w-100' src={item.logo} alt={item.nome} />
									</div>
								))}
							</div>
						</div>
						{/*<a class='carousel-control-prev' href='#carouselExampleControls' role='button' data-slide='prev'>
							<span class='carousel-control-prev-icon' aria-hidden='true'></span>
							<span class='sr-only'>Anterior</span>
						</a>
						<a class='carousel-control-next' href='#carouselExampleControls' role='button' data-slide='next'>
							<span class='carousel-control-next-icon' aria-hidden='true'></span>
							<span class='sr-only'>Próximo</span>
						</a>*/}
					</Grid>
							
				</div>		
			</Content>
		)
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	aguardando: state.previsao.aguardando,
	lista: state.previsao.lista,
	imagens: state.previsao.imagens
});
const mapDispatchToProps = dispatch => bindActionCreators({
	buscarPrevisao, buscarImagens
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Previsao)
