const INITIAL_STATE = {
	aguardando: false,
	lista: [],
	imagens: {}
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {

		case 'PREVISAO_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

		case 'PREVISAO_TEMPO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};
		
		case 'PREVISAO_IMAGEM_LISTADO':
			return {
				...state,
				imagens: action.payload.data
			}

        default:
            return state;
    }
}
