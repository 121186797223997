import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { } from './dashboardActions';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';

import imagemUser from '../assets/images/user.jpg';

import {
	buscarTempo, buscarImagens
} from './dashboardActions';

class Dashboard extends Component {

	state = {
		pesquisar: '',
		carrocelSelecionado: 0,
		codigo_acesso: null,
		botaoAtivoAjuricaba: false,
		botaoAtivoIjui: false
	}

	constructor(props) {
		super(props);
		
		this.state = {
			botaoAtivoAjuricaba: true,
			botaoAtivoIjui: false
		};

		setInterval(() => {
			this.proximoCarrocel();
		}, 10000);
	}
	
	anteriorCarrocel() {
		
		this.setState({
			...this.state,
			carrocelSelecionado: this.state.carrocelSelecionado - 1 == -1 ? 0 : this.state.carrocelSelecionado - 1 
		});
	}

	proximoCarrocel() {

		let { tempo, imagens } = this.props;

		let banners = (imagens.banners || []).filter(banner => (imagens.carrocel || []).includes(banner.id));

		this.setState({
			...this.state,
			carrocelSelecionado: this.state.carrocelSelecionado + 1 == banners.length ? 0 : this.state.carrocelSelecionado + 1
		});
	}

    componentDidMount() {

		this.props.buscarTempo(this.state.codigo_acesso);
		this.props.buscarImagens();

		setInterval(() => {
			this.props.buscarTempo(this.state.codigo_acesso);
			this.props.buscarImagens();
		}, 40000);
    }

	  
    render() {

		let { tempo, imagens } = this.props;

		let banners = (imagens.banners || []).filter(banner => (imagens.carrocel || []).includes(banner.id));
		{console.log(this.state.tempo)}

		return (
			<Content>
				{console.log(this.props.sessao && this.props.sessao.codigo_acesso) &&
				<div></div>}
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<Grid cols='12 12 10 6'>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: imagens.tela1 && imagens.tela2 ? 'space-between' : 'center', verticalAligment: 'center', alignItems: 'center', marginBottom: 2 }}>
							{imagens.tela1 ? (
								<img
									style={{ width: 120, height: 90 }}
									src={imagens.tela1} />
							) : null}

							{imagens.tela2 ? (
								<img
									style={{ width: 120, height: 90 }}
									src={imagens.tela2} />
							) : null}
						</div>
					</Grid>
					<Grid cols='12 12 10 6'>
						<div class="botoes" style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '10px', backgroundColor: '#ffffff', marginBottom: '0.50rem'}} >
							<div style={{width: '50%'}}>
								<div class="botao-ajuricaba">
									<button style={{ width: '100%', textAlign: 'center', padding: '10px', color: '#ffffff', backgroundColor: this.state.botaoAtivoAjuricaba ? '#0019bb' : '#0045bb', borderRadius: '5px', display: 'inline-block'}} 
									className={this.state.botaoAtivoAjuricaba ? 'active' : ''}
									onClick={() => {
										this.props.buscarTempo(null);
										this.setState({ codigo_acesso: null, botaoAtivoIjui: false, botaoAtivoAjuricaba: true });
									}} 
									onMouseEnter={(e) => { if (!this.state.botaoAtivoAjuricaba) { e.target.style.backgroundColor = '#0019bb'}}} 
									onMouseLeave={(e) => { if (!this.state.botaoAtivoAjuricaba) { e.target.style.backgroundColor = '#0045bb'}}}>AJURICABA</button>
								</div>
							</div>
							<div style={{width: '50%'}}>
                       			<div className="botao-ijui">
                            		<button style={{ width: '100%', textAlign: 'center', padding: '10px', color: '#ffffff', backgroundColor: this.state.botaoAtivoIjui ? '#0019bb' : '#0045bb', borderRadius: '5px', display: 'inline-block' }}
									className={this.state.botaoAtivoIjui ? 'active' : ''}
									onClick={() => {
                                		this.props.buscarTempo("codigoAcessoIjui");
										this.setState({ codigo_acesso: "codigoAcessoIjui", botaoAtivoAjuricaba: false, botaoAtivoIjui: true  });
									}} 
									onMouseEnter={(e) => { if (!this.state.botaoAtivoIjui) { e.target.style.backgroundColor = '#0019bb'}}} 
									onMouseLeave={(e) => { if (!this.state.botaoAtivoIjui) { e.target.style.backgroundColor = '#0045bb'}}}>IJUÍ</button>
								</div>
							</div>
						</div>
					</Grid>
					<Grid cols='12 12 10 6'>
						<div class="info-box shadow-sm">
							<div class="info-box-content" style={{ alignItems: 'center', paddingTop: 10, paddingBottom: 12.5 }}>
								<h2>{FormatUtils.formatarValorTela(tempo.temperatura, 0)} °C</h2>
								<h6>{DateFormat.formatarDataHoraSqlParaDescritivo(tempo.datahora)}</h6>
								<h7>{tempo.local}</h7>
							</div>			
						</div>
					</Grid>
					<Grid cols='12 12 10 6'>
						<div class="info-box shadow-sm">
						<span class="info-box-icon bg-primary"><i class="fas fa-tint"></i></span>
							<div class="info-box-content" style={{ paddingLeft: '10px'}}>
								<span class="info-box-number">{FormatUtils.formatarValorTela(tempo.umidade_ar, 0)} %</span>
								<span class="info-box-text">Umidade do Ar</span>
							</div>
						</div>
					</Grid>
					<Grid cols='12 12 10 6'>
						<div class="info-box shadow-sm">
						<span class="info-box-icon bg-primary"><i class="fas fa-wind"></i></span>
							<div class="info-box-content" style={{ paddingLeft: '10px'}}>
							<span class="info-box-number">{FormatUtils.formatarValorTela(tempo.velocidade_vento_vento, 0)} km/h - {tempo.sigla_direcao_vento || ''}</span>
								<span class="info-box-text">Vento</span>
							</div>
						</div>
					</Grid>
					<Grid cols='12 12 10 6'>
						<div class="info-box shadow-sm">
						<span class="info-box-icon bg-primary"><i class="fas fa-cloud-showers-heavy"></i></span>
							<div class="info-box-content" style={{ paddingLeft: '10px'}}>
								<span class="info-box-number">{FormatUtils.formatarValorTela(tempo.chuva, 1)} mm</span>
								<span class="info-box-text">Chuva</span>
							</div>
						</div>
					</Grid>
					<Grid cols='12 12 10 6'>
						<div class="info-box shadow-sm">
						<span class={`info-box-icon bg-${tempo.aplicacao ? 'success' : 'danger'}`}><i class="fas fa-spray-can"></i></span>
							<div class={`info-box-content bg-${tempo.aplicacao ? 'success' : 'danger'}`} style={{ paddingLeft: '10px'}}>
								<span class="info-box-number">{tempo.aplicacao ? 'Favorável' : 'Desfavorável'}</span>
								<span class="info-box-text">Condições de Aplicação</span>
							</div>
						</div>
					</Grid>
					
					<Grid cols='12 12 10 6'>
						<div id='carouselExampleControls' class='carousel slide' data-ride='carousel'>
							<div class='carousel-inner'>
								<ol class='carousel-indicators'>
									{banners.map((item, i) => (
										<li key={item.id} data-target='#carouselExampleIndicators' data-slide-to={i} class={i == this.state.carrocelSelecionado ? 'active' : null} ></li>
									))}
								</ol>
								{banners.map((item, i) => (
									<div key={item.id} class={`carousel-item ${i == this.state.carrocelSelecionado ? 'active' : ''}`}>
										<img class='d-block w-100' src={item.logo} alt={item.nome} />
									</div>
								))}
							</div>
						</div>
						{/*<a class='carousel-control-prev' href='#carouselExampleControls' role='button' data-slide='prev'>
							<span class='carousel-control-prev-icon' aria-hidden='true'></span>
							<span class='sr-only'>Anterior</span>
						</a>
						<a class='carousel-control-next' href='#carouselExampleControls' role='button' data-slide='next'>
							<span class='carousel-control-next-icon' aria-hidden='true'></span>
							<span class='sr-only'>Próximo</span>
						</a>*/}
					</Grid>
				</div>			
			</Content>
		)
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	aguardando: state.dashboard.aguardando,
	tempo: state.dashboard.tempo,
	imagens: state.dashboard.imagens
});
const mapDispatchToProps = dispatch => bindActionCreators({
	buscarTempo, buscarImagens
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
