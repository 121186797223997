import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Header from '../common/template/header';
import SideBar from '../common/template/sideBar';
import Footer from '../common/template/footer';
import Messages from '../common/msg/messages';
import imagemLogo from '../assets/images/logo.png';
import { buscarImagens } from '../dashboard/dashboardActions';

import Routes from './routes';

class App extends Component {

	state = {
		carregando: true
	}

	constructor(props) {
		super(props);
	}
	
	componentWillMount() {
		setTimeout(() => {
			this.setState({ ...this.state, carregando: false })
		}, 5000);
	}
	
	componentDidMount() {
		this.props.buscarImagens();
	}

	render() {
		return (
			<HashRouter>
				{this.state.carregando ?

					<div class='modal fade show' id='modal-overlay' style={{ display: 'block', top: '35%' }} aria-modal='true' role='dialog'>
						<div class='modal-dialog'>
							<div class='overlay'>
								<div style={{ textAlign: 'center' }}>
									{this.props.imagens &&
									<img src={this.props.imagens.abertura} style={{ width: 300 }} />}
								</div>
							</div>
						</div>
					</div>
				:
					<div className='wrapper'>
						<Header />
						<SideBar />
						<Routes />
						<Messages />
					</div>
				}
			</HashRouter>
		);
	}
}


const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	imagens: state.dashboard.imagens
});
const mapDispatchToProps = dispatch => bindActionCreators({
	buscarImagens
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(App);
