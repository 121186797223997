import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import PrevisaoReducer from '../previsao/previsaoReducer';
import HistoricoDiaReducer from '../historicoDia/historicoDiaReducer';
import HistoricoMesReducer from '../historicoMes/historicoMesReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioReducer from '../usuario/usuarioReducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
    dashboard: DashboardReducer,
    previsao: PrevisaoReducer,
    historicoDia: HistoricoDiaReducer,
    historicoMes: HistoricoMesReducer,
    usuario: UsuarioReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;
