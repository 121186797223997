import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import Previsao from '../previsao/previsao';
import HistoricoDia from '../historicoDia/historicoDia';
import HistoricoMes from '../historicoMes/historicoMes';

export default props => (
	<div id='id-content-wrapper' className='content-wrapper' >
		<Switch>
			<Route exact path='/' component={Dashboard} />
			<Route path='/previsao' component={Previsao} />
			<Route path='/historicoDia' component={HistoricoDia} />
			<Route path='/historicoMes' component={HistoricoMes} />
			<Redirect from='*' to='/' />
		</Switch>
	</div>
);
