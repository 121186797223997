const INITIAL_STATE = { registro: {}, modoTela: 'lista', lista: [], listaPerfil: [] };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'USUARIO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'USUARIO_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };

        case 'USUARIO_PERFIL_SELECT_LISTADO':
			return {
				...state,
				listaPerfil: action.payload.data
			};

        default:
            return state;
    }
}
